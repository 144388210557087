import { auth, provider, signInWithPopup } from '../shared/firebase.js';
import { postIdTokenToSessionLogin } from '../shared/utils.js';
import { v4 as uuidv4 } from 'uuid';

document.getElementById('loginButton').addEventListener('click', () => {
  signInWithPopup(auth, provider)
    .then(result => result.user.getIdToken(true))
    .then(idToken => {
      const requestId = uuidv4();
      console.warn(`sending token to api.thisisnoahevans.com/sessionLogin: ${idToken} | requestId: ${requestId}`);
      return postIdTokenToSessionLogin('https://api.thisisnoahevans.com/login', idToken, requestId);
    })
    .then(() => auth.signOut())
    .then(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirect')

      // if url, redirect to it
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // no url - add username & show user details
        document.getElementById('userDetails').style.display = 'block';
        document.getElementById('userName').innerText = `Welcome, ${auth.currentUser.displayName}`;
      }
    })
    .catch(error => console.error(error));
});
