export async function postIdTokenToSessionLogin(url, idToken, requestId) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-ID': requestId
    },
    credentials: 'include', // Ensure cookies are sent and received
    body: JSON.stringify({ idToken })
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data;
}


export function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + '=' + encodeURIComponent(value) +
    ';expires=' + expires +
    ';path=/' +
    ';domain=.thisisnoahevans.com' +
    ';Secure;SameSite=Lax';
}



export function getCookie(name) {
  const cookieName = `${encodeURIComponent(name)}=`;
  const cookieArray = document.cookie.split(';');
  console.log(`cookie array: ${cookieArray}`);  
  for (let cookie of cookieArray) {
    cookie = cookie.trim();
    console.log(`found a cookie: ${cookie}`);
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(cookie.substring(cookieName.length, cookie.length));
    }
  }
  return null; // Return null if the cookie is not found
}
